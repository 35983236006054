
button{
  cursor: pointer;
  &:disabled{
    opacity: 0.7;
    cursor: auto;
  }
}
.container {
  max-width: 1170px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.active_btn {
  border: 0;
  background: #FF5F46;
  box-shadow: 0px 14px 28px -16px #FF5F46;
  border-radius: 50px;
  padding: 20px 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 112.7%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  transition: 400ms ease;
  cursor: pointer;

  &:hover {
    background: #EE5139;
    box-shadow: 0px 6px 28px -16px #FF5F46;
  }

}

menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
  padding-left: 0;

}

.autherisationnav {
  display: flex;
  align-items: center;
  position: relative;

  .name {
    font-size: 14px;
    line-height: 124.7%;
    text-align: right;
    color: #202020;
    margin-right: 12px;
  }

  .avatar {
    // margin-right: 8px;
    border-radius: 120px;
    width: 36px;
    height: 36px;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      border-radius: 120%;
    }


  }

  .subbutton {
    cursor: pointer;
    padding: 8px;
  }

  .active_btn {
    margin-left: 16px;
  }

  .submenu {
    position: absolute;
    top: 60px;
    right: 150px;
    background: #F5F5F5;
    padding: 15px;
    border-radius: 15px;
  }
}

.main_block {
  margin-bottom: 150px;

  .container {
    background-color: #FFFFFF;
    box-shadow: 0px 24px 42px -12px rgba(0, 0, 0, 0.03);
    border-radius: 18px;

    // height: 70vh;
    display: flex;
  }

  .sidebar {
    // height: 100%;
    width: 71px;
    border-right: 1px solid #EEEEEE;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;

    nav {
      position: sticky;
      top: 15px;
    }

  }

  .menu-item {
    margin-bottom: 40px;
    cursor: pointer;
    position: relative;

    .pointer {
      width: 14px;
      height: 14px;
      border-radius: 120px;
      background-color: #FF5F46;

      font-weight: bold;
      font-size: 8px;
      line-height: 160.7%;
      color: #FFFFFF;
      position: absolute;
      top: -7px;
      right: -7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      fill: #BCBCBC;
      transition: 300ms ease;
    }

    &:hover {
      svg {
        fill: #FF5F46;
      }
    }



  }
  .active .menu-item svg{
    fill: #FF5F46;

  }

}

.main_content {
  width: 100%;

  // padding: 67px 29px;
  .title {
    font-weight: bold;
    font-size: 30px;
    line-height: 114%;
    color: #202020;

  }
}

.guid {
  padding: 67px 29px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    margin-bottom: 13px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 33px;
  }

  .item_row {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 160.7%;
    color: #202020;
    margin-bottom: 25px;
    width: 100%;

    svg {
      fill: #202020;
      margin-right: 12px;
      flex-basis: 30%;
      max-width: 20px;
    }
  }
}

.colored_txt {
  color: #FF5F46;
}

.bold_txt {
  font-weight: bold;
}

footer {
  background: #3C3C3C;
  padding: 50px 0;

  svg {
    margin-right: 8px;
  }

  .col_title {
    font-weight: 800;
    font-size: 14px;
    line-height: 124.7%;
    color: #FFFFFF;
    margin-bottom: 23px;
  }
  .adress .col_content .menu_item{
    &:hover{
      text-decoration: none;
    }
  }
  .col_content {
    display: flex;
    flex-direction: column;

    font-size: 14px;
    line-height: 124.7%;
    color: #FFFFFF;

    .menu_item {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      transition: 300ms ease;
      &:hover{
        text-decoration: underline;
      }
      a{
        color: #FFFFFF;
      }
    }

    img {
      margin-right: 8px;
    }

    &.foter_menu {
      flex-direction: row;

      .content_left {
        margin-right: 38px;
      }

    }
  }

  .footer_row {
    display: flex;
    justify-content: space-between;
    color: #fff;
    margin-bottom: 51px;

    &:last-child {
      border-top: 1px solid #4B4B4B;
      font-size: 14px;
      line-height: 124.7%;
      color: #848484;
      margin-bottom: 34px;
      // margin-top: 35px;
      padding-top: 35px;
    }
  }

  .adress {
    max-width: 160px;

    .menu_item {
      align-items: baseline;
    }
  }

  .toTop {
    position: fixed;
    right: 10%;
    // left: 90%;
    bottom: 10%;

    height: 52px;
    width: 52px;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;
    cursor: pointer;

    background: #FF5F46;
    box-shadow: 0px 12px 32px rgba(255, 95, 70, 0.42);
    border-radius: 120px;

    svg {
      margin-right: 0;
    }


  }
}

.personal-cabinet {
  padding: 67px 29px;

  .title {
    margin-bottom: 30px;
  }

  .content-block {
    display: flex;
  }

  .seo-navigation {
    flex-basis: 60%;
    max-width: 570px;
    margin-right: 30px;
  }

  .seo-subnavigation {
    flex-basis: 40%;
    max-width: 370px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));

    .subnavigation_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background-color: #F5F5F5;
      border-radius: 10px;
      padding: 25px 23px 30px;

      &:hover {
        background: #f0efef;
      }

      &.item-manager {
        .block-subtitle {
          font-size: 12px;
          line-height: 134%;
          text-align: center;
          color: #202020;
        }
      }

      .block-subtitle {
        font-size: 10px;
        line-height: 134%;
        text-align: center;
        color: #202020;
        max-width: 119px;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
      }

      .block-image {
        max-width: 78px;
        max-height: 78px;
        border-radius: 120px;
        object-fit: cover;
        object-position: center;
        margin-bottom: 14px;

      }

      .block-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 114%;
        text-align: center;
        color: #202020;
        margin-bottom: 19px;
      }

    }

    .active_btn {
      border-radius: 10px;
      padding: 10px 10px;

      font-weight: bold;
      font-size: 14px;
      line-height: 112.7%;

      color: #FFFFFF;
      width: 124px;
      max-width: 124px;
      text-align: center;
      text-transform: unset;


    }

  }



}

.navigation_row {
  text-decoration: none;

  display: flex;
  padding: 13px;
  align-items: center;
  background: #F5F5F5;
  border-radius: 10px;
  // width: 100%;
  margin-bottom: 8px;
  cursor: pointer;
  transition: 300ms ease;

  &.active,
  &:hover {
    background: #f0efef;

    .icon {
      transition: 300ms ease;
      background-color: #FFFFFF;

      svg {
        fill: #FF5F46;
        transition: 300ms ease;
      }
    }

  }

  .wrapper {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }

  .item-description {
    font-size: 12px;
    line-height: 114%;
    color: #202020;
  }

  .item-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 114%;
    color: #202020;
    margin-bottom: 3px;
  }

  .icon {
    // padding: 9px;
    background: #FF5F46;
    border-radius: 6px;
    max-width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    flex-basis: 40%;
    transition: 300ms ease;

    svg {
      fill: white;
      transition: 300ms ease;
    }

  }
}

.general {
  padding: 67px 100px 67px 29px;
  display: flex;

  .title {
    margin-bottom: 19px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    flex-basis: 90%;
    max-width: 770px;
  }

  .general-title {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 20px;
  }

  .general-body {
    margin-bottom: 37px;
  }

  .seo-submenu {
    flex-basis: 20%;
    max-width: 170px;
  }

  // 
  .seo-navigation {
    max-width: 170px;
  }

  .navigation_row {
    width: 170px;
    box-sizing: border-box;

    .wrapper {
      margin-right: 0;
    }

    .item-description {
      display: none;
    }
  }

  // 
}

.dialog {
  padding: 70px 100px 70px 29px;
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 19px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 22px;
  }


  .dialog-block {
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
  

    a {
      text-decoration: none;
    }
  }

  .left-block {
    max-width: 370px;
    flex-basis: 40%;
    border-right: 1px solid #EEEEEE;
  }

  .right-block {
    max-width: 600px;
    flex-basis: 65%;
    &.disabled{
      padding-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .dialog-row {
    padding: 19px 28px;
    display: flex;
    border-bottom: 1px solid #EEEEEE;
    position: relative;

    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
      object-position: center;
    }

    &:hover {
      background-color: #f0efef;
    }

    &.autor {
      align-items: center;

      &:hover {
        background-color: #fff;
      }
    }


    &.online {
      &::after {
        position: absolute;
        top: 52px;
        left: 59px;
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: #7ED320;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 120px;
      }


    }

    .dialog-preveiw {
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .dialog-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-end;
    }

    .dialog-avatar {
      border-radius: 120px;
      object-fit: cover;
      object-position: center;
      margin-right: 15px;
    }

    .autor-name,
    .diallog-oponent {
      font-size: 14px;
      line-height: 124.3%;
      color: #202020;

    }

    .last-message {
      font-size: 14px;
      line-height: 124.3%;
      color: #969696;
    }

    .diallog-time {
      font-size: 14px;
      line-height: 124.3%;
      text-align: right;
      color: #969696;
    }

    .diallog-status {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 120px;
      background-color: #FF5F46;
      width: 16px;
      height: 16px;
      font-size: 8px;
      line-height: 160.7%;
      color: #FFFFFF;
    }
  }

  .autor {
    padding: 19px 28px;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
    position: relative;

    img {
      object-fit: cover;
      object-position: center;
      border-radius: 120px;
      margin-right: 15px;
      position: relative;

      width: 48px;
      height: 48px;

    }

    .autor-name {
      font-size: 14px;
      line-height: 124.3%;
      color: #202020;
    }

    .status {
      display: none;
      font-size: 14px;
      line-height: 124.3%;

    }

    .wrapper {
      display: flex;
      flex-direction: column;
    }

    &.online {
      &::after {
        position: absolute;
        top: 52px;
        left: 59px;
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: #7ED320;
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 120px;
      }

      .status {
        color: #7ED320;
        display: block;
      }
    }
  }

  .chat-block::-webkit-scrollbar {
    width: 20px;
  }

  .chat-block::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  .chat-block::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }

  .chat-block::-webkit-scrollbar-thumb:hover {
    background: rgb(54, 56, 58);
  }

  .react-scroll-to-bottom--css-sympv-1n7m0yu {
    scrollbar-color: #b64928 #e0e0e0;
    scrollbar-width: thin;
  }

  .react-scroll-to-bottom--css-ofmti-1n7m0yu::-webkit-scrollbar {
    width: 20px;
  }

  .react-scroll-to-bottom--css-ofmti-1n7m0yu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  .react-scroll-to-bottom--css-sympv-1n7m0yu::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }

  .react-scroll-to-bottom--css-sympv-1n7m0yu::-webkit-scrollbar-thumb:hover {
    background: rgb(54, 56, 58);
  }

  .react-scroll-to-bottom--css-sympv-1tj0vk3 {
    bottom: 35px;
    right: 60px;
    background: url(img/arrow_down.svg) rgba(0, 0, 0, .2);
    background-repeat: no-repeat;
    background-position: center;
  }

  .chat-block {
    // position: relative;
    padding: 28px 32px 28px 32px;
    height: 450px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    scrollbar-width: none;

    .chat-row {
      display: flex;
      margin-bottom: 10px;

      &.oponent {
        align-items: flex-start;

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          object-position: center;
          margin-right: 16px;
          border-radius: 120%;
        }

        .wrapper {
          display: flex;
          flex-direction: column;
          max-width: 444px;
        }

        

        .chat-message {
          background: #F1F1F1;
          border-radius: 8px 8px 8px 0px;
          padding: 10px 18px;
          font-size: 14px;
          line-height: 124.3%;
          color: #202020;
          margin-bottom: 9px;
          max-width: 444px;

        }

        .time-message {
          font-size: 14px;
          line-height: 124.3%;
          color: #969696;
          align-self: flex-start;
          padding-left: 15px;
        }
      }
      .chat-message-name {
        background: none;
        font-weight: 700;
        margin-bottom: 10px;
      }

      &.user {
        // align-items: flex-end;
        justify-content: flex-end;

        img {
          order: 2;
          width: 30px;
          height: 30px;
          object-fit: cover;
          object-position: center;
          margin-left: 16px;
          border-radius: 120%;
        }

        .wrapper {
          order: 1;
          display: flex;
          flex-direction: column;
          max-width: 444px;
        }

        .chat-message {
          background: #F1F1F1;
          border-radius: 8px 8px 0px 8px;
          padding: 10px 18px;
          font-size: 14px;
          line-height: 124.3%;
          color: #202020;
          margin-bottom: 9px;
        }

        .time-message {
          font-size: 14px;
          line-height: 124.3%;
          color: #969696;
          align-self: flex-end;
          padding-right: 15px;
        }

      }
    }


  }

  .chat-block::-webkit-scrollbar {
    width: 0;
  }

  .chat-form {
    display: flex;
    padding-top: 5px;
    padding-left: 33px;
    padding-right: 27px;
    padding-bottom: 30px;

    // width: 100%;
    // position: absolute;
    &.disabled{
      opacity: 0;
      z-index: -1;
    }

    input {
      padding: 17px 37px;
      background: no-repeat center right 25px url('./img/staple.png') #F5F5F5;

      border-radius: 6px;
      color: #202020;
      border: none;
      outline: none;
      flex-grow: 2;

      &::placeholder {
        font-size: 14px;
        line-height: 138.3%;
        color: #7E7E7E;
      }
    }

    button {
      border: none;
      background: none;
      outline: none;
      margin-left: 18px;
    }
  }
}

.chat-block >div {
  scrollbar-color: #FF5F46 #e0e0e0;
  scrollbar-width: thin;
}

.chat-block >div::-webkit-scrollbar {
  width: 3px;
}

.chat-block >div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

.chat-block >div::-webkit-scrollbar-thumb {
  background: #FF5F46;
  border-radius: 10px;
}

.chat-block >div::-webkit-scrollbar-thumb:hover {
  background: #ff5f46d5;
}

.chat-block > button {
  bottom: 35px;
  right: 60px;
  background: url(img/arrow_down.svg)  #f1f1f1cb;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 120%;
  width: 30px;
  height: 30px;
}


.settings {
  padding: 67px 29px;

  .title {}
}

.notification {
  padding: 67px 100px 67px 30px;

  .title {
    margin-bottom: 19px;
  }

  .notification-row {
    margin-bottom: 23px;
    position: relative;
  }

  .notification-row_title {
    font-weight: bold;
    font-size: 18px;
    line-height: 160.7%;
    color: #202020;
    margin-bottom: 6px;

  }

  .notification-row_text {
    font-size: 16px;
    line-height: 160.7%;
    color: #202020;
  }

  .notification_sign {
    position: absolute;
    top: 9px;
    left: -14px;
    width: 9px;
    height: 9px;
    background: #FF5F46;
    border-radius: 120px;
  }



}

.docs {
  padding: 67px 100px 67px 30px;

  .title {
    margin-bottom: 19px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 63px;
  }

  .block-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .item-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 160.7%;
      color: #202020;
      margin-bottom: 23px;
    }

    .item-rows {}

    .item-row {
      margin-bottom: 14px;
      position: relative;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #EEEEEE;
      font-size: 14px;
      line-height: 160.7%;
      color: #202020;

      &:hover {
        border-bottom: 1px solid #e6e4e4;
      }
      .doc-item-col{
        width: 30%;
      }
    }

    button {
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      padding-right: 9px;
    }
  }

  .docs-block {
    .settings {
      position: absolute;
      top: -16px;
      right: 26px;
      flex-direction: column;
      padding: 0;
      background: #F5F5F5;
      border-radius: 10px;
      z-index: 1;
      a {
        text-decoration: none;
        font-size: 14px;
        line-height: 160.7%;
        color: #7E7E7E;
        transition: 300ms ease;

        &:hover {
          text-decoration: underline;
        }
      }

      .settings_item {
        padding: 8px 18px;
        border-bottom: 1px solid #EEEEEE;
        display: block;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}


.position {

  padding: 63px 100px 63px 29px;
  display: flex;

  .title {
    margin-bottom: 19px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    flex-basis: 90%;
    max-width: 770px;
  }

  .general-title {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 20px;
  }

  .general-body {
    margin-bottom: 37px;
  }

  .seo-submenu {
    flex-basis: 20%;
    max-width: 170px;
  }

  // 
  .seo-navigation {
    max-width: 170px;
  }

  .navigation_row {
    width: 170px;
    box-sizing: border-box;

    .wrapper {
      margin-right: 0;
    }

    .item-description {
      display: none;
    }
  }
}



.index {
  padding: 63px 100px 63px 29px;
  display: flex;
  justify-content: space-between;
  .title {
    margin-bottom: 19px;
  }

  .subtitle {
    margin-bottom: 19px;
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 20px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    flex-basis: 90%;
    max-width: 770px;
    max-width: calc(100% - 230px);
  }

  .general-title {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 20px;
  }

  .general-body {
    margin-bottom: 37px;
  }

  .seo-submenu {
    flex-basis: 20%;
    max-width: 170px;
  }

  // 
  .seo-navigation {
    max-width: 170px;
  }

  .navigation_row {
    width: 170px;
    box-sizing: border-box;

    .wrapper {
      margin-right: 0;
    }

    .item-description {
      display: none;
    }
  }

  .index-block_title {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .my-tooltip {
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 10px;
  }

  .index-block_traffic {
    // max-width: 100%;
  }

  .recharts-wrapper {
    // width: 100%;
  }

  table {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;
    th {
      color: #202020;
      padding: 5px;
      background-color: #F5F5F5;

      &:first-child {
        border-radius: 13px 0 0 0;
      }

      &:last-child {
        border-radius: 0 13px 0 0;
      }
    }

    td {
      padding: 5px;
    }

    tr {

      // &::nth-child(even){
      //   background-color: #eb7f1a;
      // }
      &:first-child {
        background-color: none;
        td{
          color: #202020;
          padding: 5px;
          background-color: #F5F5F5;
    
          &:first-child {
            border-radius: 13px 0 0 0;
          }
    
          &:last-child {
            border-radius: 0 13px 0 0;
          }
        }
      }


      text-align: center;

      &:hover {
        background-color: #f5f5f5d5;
      }
    }

    tr:nth-child(odd) {
      background-color: #ffe2dc;
      // color: #fff;
    }
  }
}

.report {
  padding: 63px 100px 63px 29px;
  display: flex;

  .title {
    margin-bottom: 19px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 22px;

  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    flex-basis: 90%;
    max-width: 770px;
  }

  .general-title {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 20px;
  }

  .general-body {
    margin-bottom: 37px;
  }

  .seo-submenu {
    flex-basis: 20%;
    max-width: 170px;
  }

  // 
  .seo-navigation {
    max-width: 170px;
  }

  .navigation_row {
    width: 170px;
    box-sizing: border-box;

    .wrapper {
      margin-right: 0;
    }

    .item-description {
      display: none;
    }
  }

  .general-progects_row {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    font-weight: bold;
    font-size: 18px;
    line-height: 160.7%;
    color: #202020;

    div {
      margin-right: 15px;
    }
  }

  .general-block_info {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 12px;
  }

  .general-body {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
  }


}

select {
  padding: 4px 14px;
  border: none;
  outline: none;

  background-color: #F5F5F5;
  border-radius: 10px;
  font-size: 14px;
  line-height: 160.7%;
  color: #7E7E7E;

  option {
    border: none;
    outline: none;
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 5px;
    margin: 5px;

  }
}

.services {
  padding: 63px 100px 63px 29px;
  display: flex;

  .title {
    margin-bottom: 30px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    flex-basis: 90%;
    max-width: 770px;
  }

  .seo-submenu {
    flex-basis: 20%;
    max-width: 170px;
  }

  // 
  .seo-navigation {
    max-width: 170px;
  }

  .navigation_row {
    width: 170px;
    box-sizing: border-box;

    .wrapper {
      margin-right: 0;
    }

    .item-description {
      display: none;
    }
  }

  .services-block_item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;

    img {
      width: 30%;
      margin-right: 30px;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
    }

    .services-block_right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .services-block_title {
      font-weight: bold;
      font-size: 18px;
      line-height: 160.7%;
      color: #202020;
      margin-bottom: 11px;
    }

    .services-block_info {
      font-size: 14px;
      line-height: 130.7%;
      color: #7E7E7E;
      margin-bottom: 22px;
      transition: ease 300ms;
    }

    .learnmore {
      margin-right: 12px;
    }

    button {
      width: 130px;
    }

    .services-block_action {
      display: flex;
    }
  }

}

.learnmore {
  cursor: pointer;
  outline: none;
  background: none;
  border: 1px solid #BCBCBC;
  box-sizing: border-box;
  // box-shadow: 0px 10px 24px -14px #FF5F46;
  border-radius: 10px;
  padding: 9px 15px;

  font-weight: 500;
  font-size: 14px;
  line-height: 112.7%;
  text-align: center;
  color: #7E7E7E;
  transition: 300ms ease;

  &:hover {
    border-color: #FF5F46;
    background-color: #FF5F46;
    color: #FFFFFF;
    box-shadow: 0px 6px 28px -16px #FF5F46;
  }
}

.order_btn {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #FF5F46;
  box-shadow: 0px 10px 24px -14px #FF5F46;
  border-radius: 10px;
  padding: 9px 18px;


  font-weight: bold;
  font-size: 14px;
  line-height: 112.7%;
  text-align: center;
  color: #FFFFFF;
  transition: 300ms ease;

  &:hover {
    background: #EE5139;
    box-shadow: 0px 6px 28px -16px #FF5F46;
  }
}



.inspection {
  padding: 63px 100px 63px 29px;
  display: flex;

  .title {
    margin-bottom: 19px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 160.7%;
    color: #7E7E7E;
    margin-bottom: 28px;

    p {
      margin-bottom: 20px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    flex-basis: 90%;
    max-width: 770px;
  }

  .seo-submenu {
    flex-basis: 20%;
    max-width: 170px;
  }

  // 
  .seo-navigation {
    max-width: 170px;
  }

  .navigation_row {
    width: 170px;
    box-sizing: border-box;

    .wrapper {
      margin-right: 0;
    }

    .item-description {
      display: none;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .form-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 21px;
    font-size: 14px;
    line-height: 160.7%;
    color: #202020;

    button {
      margin-right: 30px;
    }

    div {
      margin-right: 30px;
    }
  }

  .form-result {
    font-size: 14px;
    line-height: 160.7%;
    color: #202020;
    margin-top: 21px;
  }
}

.news {
  padding: 63px 100px 63px 29px;
  display: flex;

  .title {
    margin-bottom: 30px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    flex-basis: 90%;
    max-width: 770px;
  }

  .seo-submenu {
    flex-basis: 20%;
    max-width: 170px;
  }

  // 
  .seo-navigation {
    max-width: 170px;
  }

  .navigation_row {
    width: 170px;
    box-sizing: border-box;

    .wrapper {
      margin-right: 0;
    }

    .item-description {
      display: none;
    }
  }

  .services-block_item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;

    img {
      margin-right: 30px;
    }

    .services-block_right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .services-block_title {
      font-weight: bold;
      font-size: 18px;
      line-height: 160.7%;
      color: #202020;
      margin-bottom: 11px;
    }

    .services-block_info {
      font-size: 14px;
      line-height: 130.7%;
      color: #7E7E7E;
      margin-bottom: 22px;

    }



    .order_btn {
      width: 115px;
    }

    .services-block_action {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time {
        font-size: 14px;
        line-height: 130.7%;
        color: #7E7E7E;
      }
    }
  }
}

.contacts {
  padding: 63px 100px 63px 29px;
  display: flex;


  .title {
    margin-bottom: 30px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    flex-basis: 90%;
    max-width: 770px;
  }

  .seo-submenu {
    flex-basis: 20%;
    max-width: 170px;
  }

  // 
  .seo-navigation {
    max-width: 170px;
  }

  .navigation_row {
    width: 170px;
    box-sizing: border-box;

    .wrapper {
      margin-right: 0;
    }

    .item-description {
      display: none;
    }
  }

  .contacts-row {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    font-weight: bold;
    max-width: 500px;
    font-size: 18px;
    line-height: 160.7%;
    color: #000000;

    .image {
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      font-weight: bold;
      font-size: 18px;
      line-height: 160.7%;
      color: #000000;

    }

    &.adres {
      align-items: flex-start;
      font-weight: normal;

    }

  }

  .map-row {

    position: relative;
    overflow: hidden;
    width: 770px;
    height: 395px;

  }

}




.modalPage {
  width: 320px;
  height: 240px;
  background-image: url("./img/modal_fon.png");
  border-radius: 12px;
  position: absolute;
  top: calc(50% - 120px);
  left: calc(50% - 160px);

  font-weight: 900;
  font-size: 23px;
  line-height: 124.7%;
  text-align: center;
  color: #181818;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    margin-top: 40px;
    border: none;
  }
}

.autorisation {
  background: no-repeat bottom right 630px url('./img/auth_background.png');
  background-size: contain;
  // height: 100vh;
  margin-bottom: 170px;

  .autorisation-side_block {
    height: 100%;

    background: #FF5F46;
    border-radius: 0px 0px 412px 0px;
  }

  header {
    // width: 100%;
    display: flex;
    justify-content: space-between;
    // position: absolute;
    padding-top: 32px;
    margin-bottom: 67px;
  }

  .header-contacts_row {
    margin-bottom: 7px;

    >svg {
      margin-right: 13px;
    }

    >a {
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 132.7%;
      color: #000000;

    }

    >button {
      border: none;
      outline: none;
      background: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 124.7%;
      text-align: right;
      color: #FF5F46;
      cursor: pointer;
      border-bottom: 1px dashed #FF5F46;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .header-contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .vertical-text {
    position: absolute;
    left: -225px;
    top: 115px;
    transform: rotate(-90deg);
    font-weight: 900;
    font-size: 144px;
    line-height: 118.7%;
    color: rgba(255, 255, 255, 0.24);

  }

  .main-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: calc(50% - 585px);
  }

  .main-block_title {
    font-size: 64px;
    line-height: 118.7%;
    color: #FFFFFF;
    margin-bottom: 29px;
  }

  .main-block_subtitle {
    font-size: 24px;
    line-height: 134.7%;
    color: #FFFFFF;
    margin-bottom: 44px;
  }

  form {
    display: flex;
    flex-direction: column;

    input {
      border: none;
      outline: none;
      background: #FFFFFF;
      border-radius: 60px;
      padding: 24px 20px;


      font-size: 14px;
      line-height: 112.7%;

      &::placeholder {
        text-transform: uppercase;
      }

      color: #7E7E7E;
      margin-bottom: 8px;

      width: 285px;

    }

    button {
      outline: none;
      border: none;
      background: linear-gradient(154.36deg, #FFBF60 8.81%, #FF8227 101.17%);
      border-radius: 50px;
      font-weight: bold;
      font-size: 14px;
      line-height: 112.7%;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      padding: 24px 15px;

    }
  }

  .form-block {
    display: flex;
    align-items: flex-end;

    a {
      font-size: 14px;
      line-height: 112.7%;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-left: 21px;
      padding: 24px 0;
    }

    //  &.forgotpas{
    //     form input{
    //       width: 286px;
    //     }
    //  }
  }
}

.callback-form {
  // position: absolute;
  // top: 50%;
  // left: 50%;


  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  overflow: hidden;
  background-color: #7e7e7ebe;

  form {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(50% - 180px);
    left: calc(50% - 175px);
    // background: linear-gradient( 154.36deg, #FFBF60 8.81%, #FF8227 101.17%);
    // background: #fff;
    border-radius: 12px;
    padding: 52px 45px;
    background: no-repeat url('./img/modal_fon_big.jpg');
    background-size: cover;
  }

  .callback-exit {
    position: absolute;
    top: 0;
    right: 5px;
    background: none;
  }
  input {
    border: none;
    outline: none;
    background: #FFFFFF;
    border-radius: 60px;
    padding: 24px 20px;


    font-size: 14px;
    line-height: 112.7%;

    &::placeholder {
      text-transform: uppercase;
    }

    color: #7E7E7E;
    margin-bottom: 8px;

    width: 285px;

  }

  button {
    outline: none;
    border: none;
    background: linear-gradient(154.36deg, #FFBF60 8.81%, #FF8227 101.17%);
    border-radius: 50px;
    font-weight: bold;
    font-size: 14px;
    line-height: 112.7%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 24px 15px;
    transition: ease 300ms;
    &:disabled{
      background: linear-gradient(154.36deg, #e2bb80c9 8.81%, #dd8343c2 101.17%);
    }

  }
}

.inspection-acts{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .order_btn{
    min-width: 25%;
    margin-bottom: 15px;
  }
}

@media screen and (max-width:1700px) {

  .autorisation {

    // background: no-repeat bottom right 119%  url('./img/auth_background.png');
    background-position: bottom right 500px;
    // background-size: contain;
    background-size: cover;

    .main-block {
      img {
        width: 50vw;
      }

    }

    .vertical-text {
      left: -400px;
    }
  }
}
.fancy-img img{
  max-width: 100%;
}
.table-block{
  max-width: 100%;
  overflow-x: auto;
  scrollbar-color: #6a7d9b #f0f2f5;
  scrollbar-width: thin;
}

// .table-block {height: 70px;width: 340px;background: whitesmoke;overflow-y: scroll;border:1px solid;}
.table-block::-webkit-scrollbar-track {border-radius: 2px;}
.table-block::-webkit-scrollbar {width: 1px;}
.table-block::-webkit-scrollbar-thumb {border-radius: 2px;background: #f0f2f5;}
.table-block:hover::-webkit-scrollbar-thumb {background: #6a7d9b;}



.recharts-responsive-container{
  overflow-x: auto;
}
@media screen and (max-width:1300px) {
  .autorisation {

    // background: no-repeat bottom right 119%  url('./img/auth_background.png');
    background-position: bottom right 290px;
    // background-position: bottom right 450px;
    background-size: cover;

    .main-block {
      img {
        width: 40vw;
      }
    }

    .main-block_title {
      font-size: 34px;

    }
  }

}

@media screen and (max-width:1460px) {

  .autorisation .vertical-text {
    display: none;
  }
}

@media screen and (max-width:1200px) {

  .autorisation {

    background-position: bottom right 290px;

    .main-block {
      margin-left: 15px;
    }

  }



  .docs {
    padding: 45px 15px 45px 15px;
  }

  .services,
  .inspection,
  .guid,
  .dialog,
  .general,
  .position,
  .index,
  .report,
  .news,
  .contacts,
  .notification {
    padding: 45px 0px 45px 15px;
  }

  .main_block {
    margin-bottom: 120px;
  }

  .autherisationnav {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  menu {
    margin-bottom: 15px;
  }

  .contacts {
    .map-row {
      width: 100%;
      overflow: hidden;
    }
  }

}

@media screen and (max-width:991px) {
  .autorisation {
    margin-bottom: 60px;
    background-position: bottom right 290px;

    .form-block {
      align-items: flex-start;
      flex-direction: column;
    }

  }

  .main_content .docs {
    .docs-block {
      overflow: auto;

      .block-item {
        width: 725px;
      }
    }

    .subtitle {
      margin-bottom: 30px;
      font-style: 14px;
    }
  }

  footer .footer_row {
    flex-wrap: wrap;
    justify-content: flex-start;

    .footer_col {
      margin-right: 20px;
      margin-left: 20px;
      margin-bottom: 30px;
    }
  }

  .main_content .title {
    font-size: 24px
  }

  .services,
  .news {
    .services-block_item {
      flex-direction: column;

      img {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        align-self: flex-start;

      }

      .services-block_title {
        align-self: center;
      }

      .services-block_action {
        align-self: center;
      }
    }
  }

  .news {
    .services-block_action {
      flex-direction: column;

      .time {
        order: 1;
        margin-bottom: 15px;
      }

      .order_btn {
        order: 2;
      }
    }
  }

  .inspection {
    .form-row {
      flex-wrap: wrap;

      button {
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }

  .main_block .sidebar {
    padding-left: 5px;
    padding-right: 5px;
  }

  .main_block .container {
    padding-left: 0;
  }

  .personal-cabinet {
    padding: 45px 0px 45px 15px;

    .content-block {
      flex-direction: column;
      // align-items: center;
    }

    .seo-navigation {
      flex-basis: 100%;
      max-width: none;
      margin-right: 0px;
    }

    .seo-subnavigation {
      flex-basis: 100%;
      max-width: none;
      grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    }
  }

  .dialog {
    .chat-block {
      padding: 15px;
    }

    .left-block {
      max-width: 165px;
      flex-basis: 40%;
    }

    .dialog-row {
      flex-direction: column;
      align-items: center;
      padding: 15px 0;

      &.autor {
        // align-items: start;
        padding: 15px;
      }

      img {
        margin-bottom: 10px;
      }

      .diallog-oponent {
        margin-bottom: 7px;
      }

      .last-message {
        margin-bottom: 10px;
      }

      .dialog-info {
        align-items: flex-start;
        justify-content: start;
        flex-direction: row;

        .diallog-time {
          text-align: start;
          margin-right: 10px;
        }
      }
    }

    .right-block {
      .autor {
        height: 67px;
      }
    }

  }

  .contacts .contacts-row {
    font-size: 14px;

    a {
      font-size: 14px;
    }
  }
}

@media screen and (max-width:690px) {
  .autorisation {
    background-position: bottom right 0px;

    .header-contacts_row {
      >a {
        color: #fff;
      }

      button {
        color: #FFBF60;
        border-bottom: 1px dashed #FFBF60;
      }
    }

    header {
      margin-bottom: 30px;
    }

    .main-block {
      .main-block_info {
        margin: auto;
      }

      img {
        display: none;
      }
    }

  }

}

@media screen and (max-width:660px) {
  .main_block .sidebar {
    max-width: 31px;
    flex-basis: 40%;
  }

  .main_content {
    width: 95%;
  }

  .dialog {
    .chat-form {
      padding: 5px 0 0px;

      input {
        padding: 17px;
      }

      button {
        margin-left: 5px;
      }
    }

    .dialog-block {
      flex-direction: column;
      width: 100%;
    }

    .left-block {
      max-width: none;
      flex-basis: 100%;
      display: flex;
      flex-direction: row;
      overflow-y: auto;
      flex-basis: auto;

      scrollbar-color: rgb(190, 190, 190) #d4d4d4;
      scrollbar-width: thin;

    }

    .left-block::-webkit-scrollbar-track {
      border-radius: 4px;
    }

    .left-block::-webkit-scrollbar {
      width: 2px;
    }

    .left-block::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #f0f2f5;
    }

    .dialog-row {
      padding: 15px;
      border-right: 1px solid #EEEEEE;
      min-width: 150px;
    }

    .dialog-row.autor {
      display: none;
    }

    .right-block {
      .autor {
        border-top: 1px solid #EEEEEE;
      }
    }
  }
}

@media screen and (max-width:600px) {

  .services,
  .news,
  .inspection,
  .general,
  .position,
  .index,
  .report,
  .contacts {
    .navigation_row {
      width: 48px;
      padding: 5px;

      .icon {
        flex-basis: 100%;
        margin-right: 0;
      }

      .wrapper {
        display: none;
      }
    }


  }
  .index .wrapper{
    max-width: calc(100% - 90px);
  }
  .report .general-progects_row {
    flex-direction: column;
    align-items: flex-start;

    div {
      margin-bottom: 7px;
    }
  }

  .autherisationnav .active_btn {
    margin-top: 15px;
  }

  .main_block {
    margin-bottom: 45px;
  }

  .main_content .title {
    font-size: 20px;
  }

  .guid {
    .subtitle {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .item_row {
      svg {
        display: block;
        flex-basis: 45%;
        max-width: 20px;
      }
    }
  }

  .contacts .map-row {
    height: 250px;
  }
}


@media screen and (max-width:450px) {
  .autorisation {
    margin-bottom: 10px;
    background-position: bottom right -250px;

    header {
      margin-bottom: 30px;
    }

    .main-block {
      .main-block_info {
        margin: auto;
      }

      img {
        display: none;
      }
    }

  }


  .services,
  .news {
    .services-block_action {
      flex-direction: column;

      .learnmore {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    .wrapper {
      margin-right: 15px;
      max-width: calc(95% - 48px);

    }
  }


  .callback-form form {
    left: 0;
    right: 0;

    input {
      width: auto;
    }
  }
}

@media screen and (max-width:340px) {

  .autorisation {

    .header-contacts_row>svg {
      display: none;
    }
  }

}

.square {
  width: 80px;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.circle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    opacity: 0%;
  }

  40% {
    opacity: 20%;
  }

  100% {
    transform: rotate(360deg);
    opacity: 100%;
  }
}

@keyframes color {
  from {
    background: #FF5F46;
  }

  to {
    background: #f86852;
  }
}

.loader_wrapper {
  width: 100%;

  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.square {
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transition-timing-function: linear;
}

.circle {
  animation-name: color;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transition-timing-function: linear;
}

.circle {
  background: red;
}
.settings_form{
  margin-top: 30px;
}
.settings_instraction{
  margin-top: 40px;
}
.settings_row{
  &.row_title{
    margin-bottom: 30px;
  }
  display: flex;
  .settings_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      border-radius: 120%;
      object-fit: cover;
      object-position: center;
      height: 100px;
      width: 100px;
    }
  }
  .settings_col{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    &:first-child{
      // width: 30%;
      
    }
  }
  .active_btn{
    margin: auto;
  }
}
@media screen and  (max-width: 565px) {
  .settings_row{
    flex-direction: column;
    .settings_col{
      width: 100%;
    }
  }
  
}
.settings_row input{
  border: 1px solid;
    outline: none;
    background: #FFFFFF;
    border-radius: 60px;
    padding: 24px 20px;
    font-size: 14px;
    line-height: 112.7%;
    color: #7E7E7E;
    margin-bottom: 8px;
    width: 285px;
}

.drug_img{
  border: 1px dashed #FF5F46;
  background: none;
  padding: 20px 35px;
    font-weight: bold;
    font-size: 14px;
    line-height: 112.7%;
    text-align: center;
    text-transform: uppercase;
    transition: 400ms ease;
    color: #202020;
    cursor: pointer;
    margin-bottom: 30px;

}
.image-item__btn-wrapper{
  margin-bottom: 30px;
}

.settings_row input.avatar-input{
  position: absolute;
  z-index: -4;
  width: 0;
  height: 0;
  border: none;
  opacity: 0;
}
.avatar-label{
// display: flex;
// align-items: center;
// justify-content: center;
// border: 1px solid #7E7E7E;
// width: 285px;
// background: #FFFFFF;
// border-radius: 60px;
// padding: 24px 0;
}
@media screen and (max-width:565px) {
  .settings_row .active_btn{

  }
  .settings_row .settings_col{
    margin-bottom: 30px;
  }
  
}


#preveiw{
  max-width: 100%;
  // height: 100%;
  img{
    border-radius: 120%;
    object-fit: cover;
    object-position: center;
    height: 100px;
    width: 100px;
  }
}
.settiongs_avatar{
  border: 2px dashed #ee51397a;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: 300ms ease;
}