@import './font/stylesheet.css';


body {
  // background-color: #fff;
  background-color: #E5E5E5;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Gilroy', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
button, a, input{
  font-family: 'Gilroy', sans-serif;
  box-sizing: border-box;
}
a{
  text-decoration: none;
}
code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  //   monospace;
  font-family: 'Gilroy', sans-serif;
}
